import React, { useState, useEffect } from 'react'
import DOMPurify from 'dompurify'
import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'

// Components
import SpellTag from '../SpellTags/SpellTag'
import CollapseButton from './CollapseButton'

// Style
import './BossJournal.css'

// Compare Tool
import { diff } from 'deep-diff'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued'
// import { fontSize } from '@mui/system'

function BossJournal({ activeBuild, compareBuild, bossId, difficulty }: BossJournalProps) {
  const [isLoading, setLoading] = useState(false)
  const [journal, setJournal] = useState<Journal>({
    journalSection: [],
    name_lang: '',
  })
  const [collapsed, setCollapsed] = useState(false)
  let storedHiddenSections = []
  const item = localStorage.getItem('hiddenSections')
  if (item) {
    try {
      storedHiddenSections = JSON.parse(item)
    } catch (error) {
      console.error('Parsing stored hidden sections failed', error)
    }
  }
  const [hiddenSections, setHiddenSections] = useState(storedHiddenSections)
  useEffect(() => {
    localStorage.setItem('hiddenSections', JSON.stringify(hiddenSections))
    checkCollapsed(journal)
  }, [hiddenSections])
  const [compareBuildData, setCompareBuildData] = useState<any[] | null>(null)

  function findTitleLang(obj: any, path: string[]): string {
    let current: any = obj
    let parents: any[] = []
    let keys: string[] = []

    for (let i = 0; i < path.length; i++) {
      if (current[path[i]] === undefined) {
        return 'Unkown Title'
      }
      parents.push(current)
      keys.push(path[i])
      current = current[path[i]]
    }

    while (parents.length > 0) {
      if (current.hasOwnProperty('title_lang')) {
        return current.title_lang
      }
      current = parents.pop()
      keys.pop()
    }
    return 'Unkown Title'
  }

  useEffect(() => {
    setCompareBuildData(null)
    if (!compareBuild || isLoading) {
      return
    } else if (compareBuild === activeBuild) {
      return
    }
    const host = process.env.REACT_APP_API_URL
    fetch(host + compareBuild + '/boss/' + bossId + '/' + difficulty)
      .then((response) => response.json())
      .then((data) => {
        function sortSpellFlags(obj: any) {
          for (let key in obj) {
            let value = obj[key]
            if (value.spellFlags) {
              value.spellFlags.sort((a: [number, string], b: [number, string]) => {
                if (a[1] === b[1]) {
                  return String(a[0]).localeCompare(String(b[0]))
                }
                return Number(a[1]) - Number(b[1])
              })
            }
            if (value.child) {
              sortSpellFlags(value.child)
            }
          }
        }

        sortSpellFlags(data.journalSection)
        sortSpellFlags(journal?.journalSection)

        const differences = diff(journal, data, (path, key) => {
          if (path.includes('spellFlags')) {
            return key === 1
          }
          return ['buildguid', 'journalsectionxdifficulty'].includes(key)
        })
        interface Differences {
          lhs: string
          rhs: string
          nameLang: string
        }
        if (differences) {
          const filteredDifferences = differences.filter((difference: any) => {
            const lhs = difference.lhs === undefined ? '' : difference.lhs
            const rhs = difference.rhs === undefined ? '' : difference.rhs
            return !(typeof lhs === 'number' || typeof rhs === 'number' || lhs === rhs) || difference.kind === 'A'
          })

          // filteredDifferences.forEach((difference: any) => {
          //   const nameLang = findTitleLang(journal, difference.path as string[])

          //   console.log(nameLang, difference)
          // })

          const mappedDifferences = filteredDifferences.map((difference: any): Differences => {
            const nameLang = findTitleLang(journal, difference.path as string[])
            if (difference.kind === 'E') {
              let lhs = difference.lhs.replace(/<br\s*\/?>/gi, '\n')
              let rhs = difference.rhs.replace(/<br\s*\/?>/gi, '\n')

              return {
                lhs: lhs || '',
                rhs: rhs || '',
                nameLang: nameLang,
              }
            } else if (difference.kind === 'A') {
              const kind = difference.item.kind
              return {
                lhs: kind === 'D' ? difference.item.lhs[0] : '',
                rhs: kind === 'N' ? difference.item.rhs[0] : '',
                nameLang: nameLang,
              }
            } else if (difference.kind === 'D') {
              return {
                lhs: difference.lhs[0].bodytext_lang,
                rhs: '',
                nameLang: `New: ${nameLang}`,
              }
            } else if (difference.kind === 'N') {
              return {
                lhs: '',
                rhs: difference.rhs[0].bodytext_lang,
                nameLang: `Deleted: ${nameLang}`,
              }
            }
            console.log(difference)
            return {
              lhs: '',
              rhs: '',
              nameLang: 'Unkown Change, Kind: ' + difference.kind,
            }
          })
          const cleanedDifferences = mappedDifferences.map((difference: Differences) => {
            let lhs = typeof difference.lhs === 'string' ? difference.lhs.replace(/<[^>]*>/g, '') : difference.lhs
            let rhs = typeof difference.rhs === 'string' ? difference.rhs.replace(/<[^>]*>/g, '') : difference.rhs

            return {
              nameLang: difference.nameLang,
              lhs: lhs,
              rhs: rhs,
            }
          })
          setCompareBuildData(cleanedDifferences)
        } else {
          setCompareBuildData([])
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [compareBuild, isLoading])

  const checkCollapsed = (j: any) => {
    if (j.length === 0) {
      return
    }
    setCollapsed(true)
    j.journalSection.forEach((section: any) => {
      if (hiddenSections[section.id] === false) {
        setCollapsed(false)
      }
    })
  }

  useEffect(() => {
    if (bossId === 0) {
      setJournal({ journalSection: [], name_lang: '' })
      return
    } else {
      setLoading(true)
    }
    if (activeBuild === '' || activeBuild === undefined) {
      return
    }
    const host = process.env.REACT_APP_API_URL
    fetch(host + activeBuild + '/boss/' + bossId + '/' + difficulty)
      .then((response) => response.json())
      .then((data) => {
        setJournal(data)
        setLoading(false)
        checkCollapsed(data)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }, [bossId, activeBuild, difficulty])

  const toggleHidden = (id: string) => {
    setHiddenSections((prevState: { [key: string]: boolean }) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  function GetFlags(flags: any) {
    const flagMap = {
      1: [1, 'Tank'], // 1      Tank
      2: [2, 'Dps'], // 2      Dps
      3: [4, 'Healer'], // 4      Healer
      4: [8, 'Heroic'], // 8      Heroic
      5: [16, 'Deadly'], // 16     Deadly
      6: [32, 'Important'], // 32     Important
      7: [64, 'Interruptable'], // 64     Interruptable
      8: [128, 'Magic'], // 128    Magic
      9: [256, 'Curse'], // 256    Curse
      10: [512, 'Poison'], // 512    Poison
      11: [1024, 'Disease'], // 1024   Disease
      12: [2048, 'Enrage'], // 2048   Enrage
      13: [4096, 'Mythic'], // 4096   Mythic
      14: [8192, 'Bleed'], // 8192   Bleed
    }
    let tags = []
    for (const [, value] of Object.entries(flagMap)) {
      if (flags & Number(value[0])) {
        tags.push(value[1])
      }
    }
    return tags
  }
  const wowheadDomain = 'live'

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
  }

  function GetSection(sections: any[], depth = 0, hidden = false) {
    if (sections.length === 0 && depth === 0) {
      return <p id="noJournal">This boss has no journal entries.</p>
    }
    depth = depth + 1

    return sections.map((section: any) => {
      let journalFlags = GetFlags(section.iconflags)
      let spellFlags = section.spellFlags
      spellFlags.sort((a: [number, number], b: [number, number]) => b[1] - a[1])

      // Default off to the first depth
      if (depth === 1) {
        if (!hiddenSections.hasOwnProperty(section.id)) {
          toggleHidden(section.id)
        }
        if (hiddenSections[section.id] === false && collapsed === true) {
          setCollapsed(false)
        }
      }

      return (
        <div
          className={`section ${depth === 1 && 'topSection'} section_${section.type} ${hidden ? 'hidden' : ''}`}
          key={section.id}
        >
          <div
            className={`title_lang ${journalFlags.includes('Heroic') ? 'heroic-title' : ''} ${journalFlags.includes('Mythic') ? 'mythic-title' : ''} ${hiddenSections[section.id] ? 'inactive' : 'active'} ${hidden ? 'hidden' : ''}`}
            onClick={() => toggleHidden(section.id)}
            data-section-id={section.id}
          >
            {section.spellid !== 0 ? (
              <span>
                <a
                  className="fakeLink"
                  href="/"
                  onClick={onClick}
                  data-wowhead={`"spell=` + section.spellid + `&domain=` + wowheadDomain + `"`}
                >
                  {section.title_lang}
                </a>
              </span>
            ) : (
              <span>{section.title_lang}</span>
            )}
            <div className="iconFlags">
              {journalFlags.length > 0 &&
                journalFlags.map((tag) => (
                  <Tooltip key={tag} title={tag} arrow TransitionComponent={Zoom}>
                    <img
                      key={tag}
                      className={`icon_tag tag_${tag}`}
                      src={`textures/${tag}_icon.png`}
                      alt={String(tag)}
                    />
                  </Tooltip>
                ))}
              {section.spellid !== 0 && (
                <a
                  data-disable-wowhead-tooltip="true"
                  data-wh-rename-link="false"
                  rel="noreferrer"
                  target="_blank"
                  href={`https://wowhead.com/spell=` + section.spellid}
                >
                  <img className={`icon_tag tag_wowhead`} src={`wowhead-icon.png`} alt="wowhead icon" />
                </a>
              )}
            </div>
          </div>
          {section.bodytext_lang !== '' && (
            <div className={`bodytext_lang ${hiddenSections[section.id] || hidden ? 'hidden' : ''}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(section.bodytext_lang, {
                    ADD_ATTR: ['target', 'rel'],
                  }),
                }}
              />
              {spellFlags.length > 0 && <SpellTag tags={spellFlags} />}
            </div>
          )}
          {section.child && GetSection(section.child, depth, hiddenSections[section.id])}
        </div>
      )
    })
  }

  const newDiffStyles = {
    variables: {
      dark: {
        diffViewerTitleColor: '#cad1d8',
        addedBackground: '#158996',
        wordAddedBackground: '#08a31d',
      },
    },
    titleBlock: {
      fontSize: '1.2em',
    },
  }

  return (
    <>
      <div id="journal">
        {isLoading ? (
          <div id="encounterTitle">Loading...</div>
        ) : Array.isArray(journal.journalSection) ? (
          <>
            <div id="encounterTitle">
              <CollapseButton
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                sections={journal.journalSection}
                hiddenSections={hiddenSections}
                toggleHidden={toggleHidden}
              />
              <span className="name_lang">{journal.name_lang}</span>
            </div>
            {GetSection(journal.journalSection)}
          </>
        ) : (
          <div id="encounterTitle">No Boss Selected</div>
        )}
      </div>
      {!isLoading && compareBuild ? (
        compareBuildData ? (
          <div id="comparedBuilds">
            {compareBuildData.map((entry: any, index: number) => (
              <ReactDiffViewer
                styles={newDiffStyles}
                key={index}
                oldValue={entry.rhs}
                newValue={entry.lhs}
                splitView={false}
                hideLineNumbers={true}
                useDarkTheme={true}
                leftTitle={entry.nameLang}
                showDiffOnly={true}
                extraLinesSurroundingDiff={0}
                compareMethod={DiffMethod.WORDS}
              />
            ))}
          </div>
        ) : (
          <div id="diffLoading">
            {compareBuild === activeBuild ? "You've got the same build selected. >_>" : 'Loading Comparison...'}
          </div>
        )
      ) : null}
    </>
  )
}

export default BossJournal
