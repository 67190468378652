import { useState } from 'react'
import Cookies from 'js-cookie'
import axios from 'axios'

const expires = 7 // days
const cookieName = 'echoServicesAuthToken'
const validateEndpoint = 'https://services.echoesports.gg:2096/gatekeeper/check'
const domain = 'echoesports.gg'

export default function useToken() {
  const getToken = () => {
    const tokenString = Cookies.get(cookieName)
    if (tokenString) {
      axios
        .post(validateEndpoint, { token: tokenString })
        .then((data) => {
          if (data.data.success === true) {
            Cookies.set(cookieName, tokenString, { expires: expires, domain })
          } else {
            console.log('Token is invalid, removing')
            Cookies.remove(cookieName, { domain })
          }
        })
        .catch(() => {
          Cookies.remove(cookieName, { domain })
          setToken('')
        })
    }
    return tokenString
  }

  const [token, setToken] = useState(getToken())
  const storeToken = (userToken: string) => {
    Cookies.set(cookieName, userToken, { expires: expires, domain })
    setToken(userToken)
  }

  return {
    setToken: storeToken,
    token,
  }
}
